html,
body {
  height: 100%;
}

.mat-drawer-container {
  background-color: $body-background-color !important;
}

.border-color-gray {
  border-color: $border-color;
}

form {
  .mat-list-base .mat-list-item {
    height: auto !important;
  }

}



.chart.ng-star-inserted {
    height: 80% !important;
}
