mat-list-item {
  margin: 10px 0px;

  &.has--two-columns {

    .is--column {
      width: 50%;
      text-align: left;

      &.is--left {
        padding-right: 10px;
      }

      &.is--right {
        padding-left: 10px;
      }
    }
  }

  &.has--four-columns {

    .is--column {
      width: 25%;
      text-align: left;

      &.is--left {
        padding-right: 10px;
      }

      &.is--right {
        padding-left: 10px;
      }
    }
  }

  &.has--three-columns {

    .is--column {
      width: 33%;
      text-align: left;

      &.is--left {
        padding-right: 10px;
      }

      &.is--right {
        padding-left: 10px;
      }
    }
  }
}

.fieldset--selections {

  mat-checkbox {

    label {
      white-space: pre-wrap;
    }
  }
}
