@use "sass:math";

.icon--group {
  display: block;
  font-size: 0px;

  .icon--group-element {
    display: inline-block;
    font-size: 16px;
    text-align: center;

    &.c-6 {
      width: math.div(100%, math.div(12, 6));
    }

    &.c-4 {
      width: math.div(100%, (12 * 0.25));
    }

    &.c-3 {
      width: math.div(100%, math.div(12, 3));
    }

    &.c-2 {
      width: math.div(100%, (12 * 0.5));
    }

    &.c-1 {
      width: math.div(100%, math.div(12, 1));
    }

    .icon {
      margin: 0px
    }
  }
}