$brand-primary: #cd013d;
$brand-primary-foreground: #ffffff;
$brand-secondary: #626d73;
$brand-secondary-foreground: #ffffff;

$body-background-color: #ffffff;

$snackbar-danger-background-color: #df0000;
$snackbar-danger-foreground-color: #ffffff;
$snackbar-warning-background-color: #ffaf00;
$snackbar-warning-foreground-color: #ffffff;
$snackbar-info-background-color: #1aa3ed;
$snackbar-info-foreground-color: #ffffff;
$snackbar-success-background-color: #00af00;
$snackbar-success-foreground-color: #ffffff;
$snackbar-accent-background-color: $brand-primary;
$snackbar-accent-foreground-color: $brand-primary-foreground;

$sidenav-background-color: $brand-secondary;
$sidenav-foreground-color: $brand-secondary-foreground;

$border-color: #f0f0f0;
