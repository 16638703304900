@import "modules/global.scss";
@import "modules/snackbar.scss";
@import "modules/sidenav.scss";
@import "modules/spacer.scss";
@import "modules/toolbar.scss";
@import "modules/table.scss";
@import "modules/list.scss";
@import "modules/toggle-button.scss";
@import "modules/breadcrumb.scss";
@import "modules/icon-group.scss";
@import "modules/slider.scss";
@import "modules/style-helper.scss";
@import "modules/assessment.scss";
@import "modules/measures.scss";
@import "modules/forms.scss";

/* additional plugins */
@import "modules/animate.scss";
