$default-space: 15px;

*[class^="mt-"],
*[class^="mb-"],
*[class^="ml-"],
*[class^="mr-"] {
  display: block;
}

/* margin-top */
.mt-1 {
  margin-top: ($default-space * 1) !important;
}

.mt-2 {
  margin-top: ($default-space * 2) !important;
}

.mt-3 {
  margin-top: ($default-space * 3) !important;
}

.mt-4 {
  margin-top: ($default-space * 4) !important;
}

.mt-5 {
  margin-top: ($default-space * 5) !important;
}

/* margin-bottom */
.mb-1 {
  margin-bottom: ($default-space * 1) !important;
}

.mb-2 {
  margin-bottom: ($default-space * 2) !important;
}

.mb-3 {
  margin-bottom: ($default-space * 3) !important;
}

.mb-4 {
  margin-bottom: ($default-space * 4) !important;
}

.mb-5 {
  margin-bottom: ($default-space * 5) !important;
}

/* margin-left */
.ml-1 {
  margin-left: ($default-space * 1) !important;
}

.ml-2 {
  margin-left: ($default-space * 2) !important;
}

.ml-3 {
  margin-left: ($default-space * 3) !important;
}

.ml-4 {
  margin-left: ($default-space * 4) !important;
}

.ml-5 {
  margin-left: ($default-space * 5) !important;
}

/* margin-right */
.mr-1 {
  margin-right: ($default-space * 1) !important;
}

.mr-2 {
  margin-right: ($default-space * 2) !important;
}

.mr-3 {
  margin-right: ($default-space * 3) !important;
}

.mr-4 {
  margin-right: ($default-space * 4) !important;
}

.mr-5 {
  margin-right: ($default-space * 5) !important;
}