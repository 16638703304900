.assessment--detail-content {

  .assessment--folder-level-1 {

  }

  .assessment--folder-level-2 {

    .mat-button-toggle-label-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 240px;
    }
  }
}