app-lws-table-inline-edit {
  .mat-column-state,
  .mat-column-budget,
  .mat-column-actual,
  .mat-column-budgetPt,
  .mat-column-actualPt,
  .mat-column-actions {
    width: 5%;
  }

}
