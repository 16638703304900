mat-toolbar {

  .toolbar-icon {
    padding: 0 14px;
  }

  .toolbar-spacer {
    flex: 1 1 auto;
  }
}
