.table-container {

  .table-loading-shade {
    text-align: center;
    padding: 15px;
  }

  mat-spinner {
    margin: 0px auto;
  }
}

.row-action {
  margin: 10px 5px;
}

.table-container {
  box-shadow: none !important;
}

.mat-header-cell {
  padding-right: 3px !important;
}
