input.ng-invalid {
  color: #f00;

}
.mat-checkbox-label {
  font-size: 10pt;
}

.risk-strategy {

  .mat-form-field-wrapper {
    max-width: 130px;

    input {
      text-align: right;
    }
  }
}
