snack-bar-container {

  &.snackbar-lws-error {
    background: $snackbar-danger-background-color;
    color: $snackbar-danger-foreground-color;
  }

  &.snackbar-lws-info {
    background: $snackbar-info-background-color;
    color: $snackbar-info-foreground-color;
  }

  &.snackbar-lws-success {
    background: $snackbar-success-background-color;
    color: $snackbar-success-foreground-color;
  }

  &.snackbar-lws-warning {
    background: $snackbar-warning-background-color;
    color: $snackbar-warning-foreground-color;
  }

  &.snackbar-lws-accent {
    background: $snackbar-accent-background-color;
    color: $snackbar-accent-foreground-color;
  }
}
