mat-sidenav-container {

}

mat-sidenav {
  background-color: $sidenav-background-color !important;
  color: $sidenav-foreground-color !important;

  .mat-list-base {

    .mat-list-item {
      color: $sidenav-foreground-color !important;
    }
  }

  .mat-card {
    background-color: darken($sidenav-background-color, 10%) !important;
    color: $sidenav-foreground-color !important;
  }
}

mat-sidenav-content {

}

.navigation--item {

  &.is--active {
    background-color: lighten($sidenav-background-color, 15%) !important;
  }
}