mat-button-toggle-group.is--list {
  border-color: transparent !important;
  flex-direction: column;
  width: 100%;

  mat-button-toggle {
    width: 100%;
    border-color: transparent !important;

    .mat-button-toggle-label-content {
      width: 100%;
      text-align: left;
    }
  }
}
